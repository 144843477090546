import React, { useState } from 'react';
import { Row, Col, Icon, List, Empty, Collapse, Input, Anchor, Card } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import SupportLayout from '../../components/support';
import SEO from '../../components/seo';
import { Link } from "gatsby"
import { isMobile } from "react-device-detect";

const { Panel } = Collapse;
const Search = Input.Search;

const generalFaqs = [
  {
    id: 'g1',
    title: 'What is RealtyStack?',
    content: 'RealtyStack is software as a service (SAAS) for managing your real estate portfolio. Realtystack helps your manage your properties, finances, residents, and more to make investing in real estate easier.',
  },
  {
    id: 'g2',
    title: 'What payment methods does RealtyStack support?',
    content: 'RealtyStack supports all major credit, debit cards, and banks for payments for leases. For collecting rent, security deposits, and other rent related items RealtyStack supports linking bank accounts as well as debit cards',
  },
  {
    id: 'g3',
    title: 'How are payments processed?',
    content: 'We use Stripe for payments processing. All of your information is kept secret and secure though our payment provider',
  },
  {
    id: 'g4',
    title: 'Is RealtyStack secure?',
    content: 'Yes, we encrypt all of our data as well maintain PCI compliant servers to keep all your information safe and secure',
  },
  {
    id: 'g5',
    title: 'How much does RealtyStack cost?',
    content: 'RealtyStack is free of charge with paid subscriptions for extra features and services',
  },
  {
    id: 'g6',
    title: 'How to contact RealtyStack',
    content: 'You can submit a support ticket through the user dashboard or send us an email at support@realtystack.io', 
  },
  {
    id: 'g7',
    title: 'Can I manage maintenance requests from my residents?',
    content: 'Residents and property managers are able to submit maintenance requests for the properties/units which they occupy and property managers, landlords, and investors can toggle notifications to manage through the dashboard', 
  },
  {
    id: 'g8',
    title: 'How do I close my RealtyStack account?',
    content: 'Navigate to the settings page and then the Accounts tab will allow you to permenantly delete your account.', 
  },
];

const residentFaqs = [
    {
      id: 'r1',
      title: 'How to get your residents to sign up with RealtyStack',
      content: 'Once logged in to your landlord account all you need is your residents email address and we will let them know to sign up. Once they have signed up we send you a notification.', 
    },
    {id: 'r2', title: 'How can I verify my tenant has signed up to make payments?',
    content: 'Once a lease has been created you can track the signup and verification progress of invited residents', },
    {id: 'r3', title: 'How long does it take for processing rental payments?',content: 'Typical time is 3-5 days for payment processing', },
    {id: 'r4', title: 'Can I bill my residents for single time payments or things other than rent?',content: 'Yes, currently security deposits can also be collected at lease creation', },
    // {id: 'r5', title: 'How can I set up automatic late fees?',content: 'bars', },
    {id: 'r6', title: 'Can I manage maintenance requests from my residents?',content: 'Yes, both residents and you can track maintenance requests for a property in one place', },
    {id: 'r7', title: 'How do I change the rent amount & due date?',content: 'Leases can be edited and updated', },
    {id: 'r8', title: 'Where can I check the status of a payment?',content: 'The payments section on the dashboard lists all payments activity.', },
    {id: 'r9', title: 'A payment from my renter failed during processing. What do I do?',content: 'Wait 3-5 days to see if the payment is able to be processed. If the error persists contact us at support@realtystack.io', },
    {id: 'r10', title: 'How do I cancel payments from my residents?',content: 'Leases can be canceled at anytime in the leases dashboard', },
    {id: 'r11', title: 'How can I collect payments from multiple roomates?',content: 'Yes, leases can be created with multiple residents', },
    {id: 'r12', title: 'Can I refund my tenants?',content: 'A refund can be requests through the payments dashboard screen', },
  ];

const paymentFaqs = [
    {id: 'p1', title: 'How can I verify my tenant has signed up to make payments?',content: 'bars', },
    {id: 'p2', title: 'How long does it take for processing rental payments?',content: 'bars', },
    {id: 'p3', title: 'Can I bill my residents for single time payments or things other than rent?',content: 'bars', },
    {id: 'p4', title: 'How can I set up automatic late fees?',content: 'bars', },
    {id: 'p5', title: 'How do I change the rent amount & due date?',content: 'bars', },
    {id: 'p6', title: 'Where can I check the status of a payment?',content: 'bars', },
    {id: 'p7', title: 'A payment from my renter failed during processing. What do I do?',content: 'bars', },
    {id: 'p8', title: 'How do I cancel payments from my residents?',content: 'bars', },
    {id: 'p9', title: 'How can I collect payments from multiple roomates?',content: 'bars', },
    {id: 'p10', title: 'Can I refund my tenants?',content: 'bars', },
];

const Faq = (props) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [state, setState] = useState({
      visible: false,
    });

    const toggleVisibility = (newVis) => {
      setState({
        ...state,
        ...newVis
      });
    };

    return (
      <SupportLayout {...props}>
        <SEO title="FAQs" description='RealtyStack frequently asked questions' />
        <Row style={{margin:'20px' }}>
            <Col sm={22} md={20} style={{marginBottom:'50px', backgroundColor:'#fff',}}>
              <h2 style={{fontSize:'28px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'prewrap'}}>
                <span style={{fontWeight:600}}>Frequently Asked Questions</span> (FAQ's)
              </h2>
              <p style={{fontSize:'20px', lineHeight:'1.2', color:'#495057'}}>
              Search all of the most frequently asked questions about RealtyStack
              </p>
              <p></p>
              <div style={{textAlign:'center'}}>
                <Search 
                size='large'
                style={{zIndex:'1', margin:'10px 0px', maxWidth:'500px'}} 
                placeholder="Search FAQs" 
                onChange={e =>  setSearchTerm(e.target.value.toLowerCase())}
                enterButton 
                />
              </div>
              <h2 id='intro' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap', margin:'20px 0px'}} id='general'>
              <span style={{fontWeight:600, margin:'20px 0px'}}>General</span>
              </h2>
              {generalFaqs.filter(item => JSON.stringify(item).toLowerCase().includes(searchTerm)).map((item, index) => {
                return <Card hoverable key={index} onClick={() => toggleVisibility({[item.id]: {"visible": state[item.id] ? !state[item.id].visible : true} })}>
                  <p style={{fontWeight:'500'}}>{state[item.id] ? state[item.id].visible ? <MinusOutlined /> : <PlusOutlined /> : <PlusOutlined />} {item.title}</p>
                  {state[item.id] ? state[item.id].visible ? item.content : null : null}
                </Card>
              })}
              <h2 id='intro' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap', margin:'20px 0px'}} id='residents'>
              <span style={{fontWeight:600, margin:'20px 0px'}}>Residents</span>
              </h2>
              {residentFaqs.filter(item => JSON.stringify(item).toLowerCase().includes(searchTerm)).map((item, index) => {
                return <Card hoverable key={index} onClick={() => toggleVisibility({[item.id]: {"visible": state[item.id] ? !state[item.id].visible : true} })}>
                  <p style={{fontWeight:'500'}}>{state[item.id] ? state[item.id].visible ?  <MinusOutlined /> : <PlusOutlined /> :  <PlusOutlined />} {item.title}</p>
                  {state[item.id] ? state[item.id].visible ? item.content : null : null}
                </Card>
              })}
              <h2 id='intro' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap', margin:'20px 0px'}} id='payments'>
              <span style={{fontWeight:600, margin:'20px 0px'}}>Payments</span>
              </h2>
              {paymentFaqs.filter(item => JSON.stringify(item).toLowerCase().includes(searchTerm)).map((item, index) => {
                return <Card hoverable key={index} onClick={() => toggleVisibility({[item.id]: {"visible": state[item.id] ? !state[item.id].visible : true} })}>
                  <p style={{fontWeight:'500'}}>{state[item.id] ? state[item.id].visible ? <MinusOutlined /> : <PlusOutlined /> : <PlusOutlined />} {item.title}</p>
                  {state[item.id] ? state[item.id].visible ? item.content : null : null}
                </Card>
              })}
            </Col>
            <Col sm={0} md={4}>
              {isMobile ? null :
              <Anchor offsetTop={120} style={{float:'right'}}>
                  <Anchor.Link href="#general" title="General" />
                  <Anchor.Link href="#residents" title="Residents" />
                  <Anchor.Link href="#payments" title="Payments" />
              </Anchor>}
            </Col>
        </Row> 
      </SupportLayout>
    )
}

export default Faq;